import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import SharedHeader from './components/shiteidouro/SharedHeader';
import SharedFooter from './components/shiteidouro/SharedFooter';

export const ShiteidouroFaq = (props) => {
  useEffect(() => {
    // タイトルをセット
    document.title = props.title;
  }, []);

  return (
    <div id="wrapper">
      <SharedHeader />
      <div className="container2">
        <h1>疑問・質問</h1>
        <div className="gry-wrap">
          <div className="faq-wrap">
            <div className="faq-tab">
              <input id="tab-uno1" type="checkbox" className="toggle" />
              <label htmlFor="tab-uno1" className="faq-title btnmode"><p className="faq-title-q">「同意する」ボタンをクリックしても動かない。</p>
              </label>
              <div className="faq-content">
                <p className="faq-content-a" >ご使用のブラウザが対応していない可能性があります。「動作環境ページ」の「対応ブラウザ」欄をご確認ください。</p>
              </div>
            </div>
            <div className="faq-tab">
              <input id="tab-uno2" type="checkbox" className="toggle" />
              <label htmlFor="tab-uno2" className="faq-title btnmode"><p className="faq-title-q">名古屋市以外の地図が表示されない。</p>
              </label>
              <div className="faq-content">
                <p className="faq-content-a" >名古屋市指定道路図は、名古屋市における建築基準法の道路種別を明示した地図です。<br />
                  そのため、市域外は表示されません。名古屋市以外の道路種別を確認されたい場合は、お調べの場所の市役所等にお問い合わせください。</p>
              </div>
            </div>
            <div className="faq-tab">
              <input id="tab-uno3" type="checkbox" className="toggle" />
              <label htmlFor="tab-uno3" className="faq-title btnmode"><p className="faq-title-q">指定道路図に記載のない道の取り扱いは?</p>
              </label>
              <div className="faq-content">
                <p className="faq-content-a" >原則、調査が必要となりますので、記載のない道の取り扱いについては、建築指導課窓口までお問い合わせください。</p>
              </div>
            </div>
            <div className="faq-tab">
              <input id="tab-uno4" type="checkbox" className="toggle" />
              <label htmlFor="tab-uno4" className="faq-title btnmode"><p className="faq-title-q">指定道路図に記載があるものの、現存しない道路の取り扱いは?</p>
              </label>
              <div className="faq-content">
                <p className="faq-content-a" >現に存在しない道路は、建築基準法の道路として取り扱うことはできません。</p>
              </div>
            </div>
            <div className="faq-tab">
              <input id="tab-uno5" type="checkbox" className="toggle" />
              <label htmlFor="tab-uno5" className="faq-title btnmode"><p className="faq-title-q">道路種別が変更されることはあるのか?</p>
              </label>
              <div className="faq-content">
                <p className="faq-content-a" >詳細調査等により新たな事実が判明した場合、道路種別を変更することがあります。</p>
              </div>
            </div>
            <div className="faq-tab">
              <input id="tab-uno6" type="checkbox" className="toggle" />
              <label htmlFor="tab-uno6" className="faq-title btnmode"><p className="faq-title-q">公道か、私道かを調べたい。</p>
              </label>
              <div className="faq-content">
                <p className="faq-content-a" >名古屋市指定道路図では、調べることはできません。<br />
                  法務局に備え付けられた公図・登記簿謄本でお調べください。</p>
              </div>
            </div>
            <div className="faq-tab">
              <input id="tab-uno7" type="checkbox" className="toggle" />
              <label htmlFor="tab-uno7" className="faq-title btnmode"><p className="faq-title-q">公道の幅員を調べたい。</p>
              </label>
              <div className="faq-content">
                <p className="faq-content-a" >名古屋市道の幅員は、名古屋市緑政土木局道路利活用課(972-2843)にお問い合わせください。<br />なお、名古屋市道路認定図のホームページ(<a href="https://www.douroninteizu.city.nagoya.jp/" target="_blank">https://www.douroninteizu.city.nagoya.jp/</a>)にて、調べることもできますのでご利用ください。</p>
              </div>
            </div>
            <div className="faq-tab">
              <input id="tab-uno8" type="checkbox" className="toggle" />
              <label htmlFor="tab-uno8" className="faq-title btnmode"><p className="faq-title-q">私道の幅員を調べたい。</p>
              </label>
              <div className="faq-content">
                <p className="faq-content-a" >私道の幅員は、名古屋市では把握しておりませんので、私道所有者等にご確認ください。<br />
                  なお、私道の幅員が不明確な場合は、関係権利者で道路協議が必要となります。</p>
              </div>
            </div>
            <div className="faq-tab">
              <input id="tab-uno9" type="checkbox" className="toggle" />
              <label htmlFor="tab-uno9" className="faq-title btnmode"><p className="faq-title-q">特定通路とは何か</p>
              </label>
              <div className="faq-content">
                <p className="faq-content-a" >この特定通路は道路ではありませんが、建築敷地が特定通路に接し、かつ、特定通路の幅員や管理形態に応じた一定の条件を満たすことにより接道許可(法第43条第2項第2号接道許可または法43条第2項第1号接道認定)を受けることができる通路状の空地です。<br />
                  なお、建築確認申請の前にこの接道許可等を受けておく必要がありますので、あらかじめ建築指導課にご相談ください。</p>
              </div>
            </div>
            <div className="faq-tab">
              <input id="tab-uno10" type="checkbox" className="toggle" />
              <label htmlFor="tab-uno10" className="faq-title btnmode"><p className="faq-title-q">現況幅員が4m以上の2項道路の取り扱いは?</p>
              </label>
              <div className="faq-content">
                <p className="faq-content-a" >2項道路と表示された道路の中には、既にセットバックが終了し、現況幅員が4m以上のものがあります。ただ中には、反対側の建物が一方的に4mセットバックし建築していることもありますので、元道の中心から相互に2mセットバックしているのか、ご確認ください。</p>
              </div>
            </div>
            <div className="faq-tab">
              <input id="tab-uno11" type="checkbox" className="toggle" />
              <label htmlFor="tab-uno11" className="faq-title btnmode"><p className="faq-title-q">
                現況幅員が4m未満の1号道路の取り扱いは?</p>
              </label>
              <div className="faq-content">
                <p className="faq-content-a" >施工精度等の理由により、現況幅員が4mに満たない1号道路については、幅員が4mとなるよう、道路を再整備(復元)してください。</p>
              </div>
            </div>
            <div className="faq-tab">
              <input id="tab-uno12" type="checkbox" className="toggle" />
              <label htmlFor="tab-uno12" className="faq-title btnmode"><p className="faq-title-q">
                表示される指定幅員と現況幅員が異なる5号道路の取り扱いは?</p>
              </label>
              <div className="faq-content">
                <p className="faq-content-a" >道路の幅員が、指定幅員となるよう、道路を再整備(復元)してください。<br />
                  方法としては、関係権利者で道路境界を協議し復元位置を決める、一方的に元道の反対側の道路境界から指定幅員分後退する、などの方法があります。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SharedFooter />
    </div>
  );
}
