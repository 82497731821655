import 'bootstrap'
import './index.scss'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import { store } from './store'
import { Provider } from 'react-redux'

import { ShiteidouroTop } from './pages/ShiteidouroTop'
import { Shiteidouro } from './pages/Shiteidouro'
import { ShiteidouroDousa } from './pages/ShiteidouroDousa'
import { ShiteidouroFaq } from './pages/ShiteidouroFaq'
import { ShiteidouroManual } from './pages/ShiteidouroManual'
import { ShiteidouroPrint } from './pages/ShiteidouroPrint'

import { KenchikuTop } from './pages/KenchikuTop'
import { Kenchiku } from './pages/Kenchiku'
import { KenchikuDousa } from './pages/KenchikuDousa'
import { KenchikuFaq } from './pages/KenchikuFaq'
import { KenchikuManual } from './pages/KenchikuManual'
import { KenchikuPrint } from './pages/KenchikuPrint'

import ScrollToTop from './pages/components/ScrollToTop';

const shiteidouroTitle = "名古屋市指定道路図";
const kenchikuTitle = "名古屋市建築情報マップ";

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ScrollToTop />
      <Switch>
        {/* 名古屋市指定道路図 */}
        <Route path="/nagoya/nagoya_shiteidouro" render={() => <Shiteidouro title={shiteidouroTitle} />} />
        <Route path="/nagoya/dousa" render={() => <ShiteidouroDousa title={shiteidouroTitle} />} />
        <Route path="/nagoya/faq" render={() => <ShiteidouroFaq title={shiteidouroTitle} />} />
        <Route path="/nagoya/manual" render={() => <ShiteidouroManual title={shiteidouroTitle} />} />
        <Route path="/nagoya/print" render={() => <ShiteidouroPrint />} />
        <Route path="/nagoya" render={() => <ShiteidouroTop title={shiteidouroTitle} />} />
        {/* 名古屋市建築情報マップ */}
        <Route path="/kenchiku/nagoya_kenchiku" render={() => <Kenchiku title={kenchikuTitle} />} />
        <Route path="/kenchiku/dousa" render={() => <KenchikuDousa title={kenchikuTitle} />} />
        <Route path="/kenchiku/faq" render={() => <KenchikuFaq title={kenchikuTitle} />} />
        <Route path="/kenchiku/manual" render={() => <KenchikuManual title={kenchikuTitle} />} />
        <Route path="/kenchiku/print" render={() => <KenchikuPrint />} />
        <Route path="/kenchiku" render={() => <KenchikuTop title={kenchikuTitle} />} />
        {/* ルートのリダイレクト処理 */}
        <Route path="/" render={() => { location.href="/nagoya/" }} />
      </Switch>
    </Router>
  </Provider>
, document.getElementById('root'));