import React from 'react';
import { Link } from 'react-router-dom';

export default class MapSelect extends React.Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.bgClass = "hide";
    this.frameClass = "hide";
    this.state = { hide: true };
  }

  toggle() {
    if (this.state.hide) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.bgClass = "fadein";
    this.frameClass = "popup-win-open";
    this.setState({ hide: false });
  }

  hide() {
    this.bgClass = "fadeout";
    this.frameClass = "popup-win-close";
    this.setState({ hide: true });
  }

  render() {
    const bgClassName = "popup-bg " + this.bgClass;
    const frameClassName = "map-s-box popup-frame " + this.frameClass;

    return (
      <div>
        <div className={bgClassName} onClick={this.hide}></div>
        <div className={frameClassName}>{/* 地図選択の囲み */}
          <div className="close-btn2 btnmode" onClick={this.hide}></div>
          <p className="map-s-title"></p>
          <div className="popup-frame-inner">
            <p className="link-txt">■地図選択</p>
            <Link to="/kenchiku/nagoya_kenchiku/">
              <div className="link-bar"><p className="mps-title">建築情報マップ</p><p className="arrow"></p></div>
              <div className="link-box"><div className="thumb">
                <div className="thumb-img thumb-img2"></div></div>
                <p className="exp">建築協定地区などの指定状況が調べられます。</p>
              </div>
            </Link>
            <p className="link-txt">■関連リンク</p>
            <a href="https://www.douroninteizu.city.nagoya.jp/" target="_blank">
              <div className="link-bar"><p className="mps-title">名古屋市道路認定図</p><p className="arrow"></p></div>
              <div className="link-box"><div className="thumb">
                <div className="thumb-img thumb-img3"></div></div>
                <p className="exp">名古屋市が管理する道路の幅員等が調べられます。</p>
              </div>
            </a>
            <a href="https://www2.wagmap.jp/nagoya-tokei/" target="_blank">
              <div className="link-bar"><p className="mps-title">名古屋市都市計画情報提供サービス</p><p className="arrow"></p></div>
              <div className="link-box"><div className="thumb">
                <div className="thumb-img thumb-img4"></div></div>
                <p className="exp">用途地域等の都市計画の指定状況が調べられます。</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }

}