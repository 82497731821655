import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import SharedHeader from './components/kenchiku/SharedHeader';
import SharedFooter from './components/kenchiku/SharedFooter';
import UpdateDate from './components/kenchiku/UpdateDate';
import Notice from './components/kenchiku/Notice';

export const KenchikuTop = (props) => {
  const history = useHistory();

  useEffect(() => {
    // タイトルをセット
    document.title = props.title;
  }, []);

  const agree = () => {
    // document.cookie = "kenchiku_agree=true;path=/;secure=true;SameSite=none";
    document.cookie = "kenchiku_agree=true;path=/"; // 非SSL環境用
    history.push("/kenchiku/nagoya_kenchiku");
  }

  return (
    <div id="wrapper">
      <SharedHeader />
      <div className="container">
        <div className="info-box">
          <h1 className="info-title">お知らせ</h1>
          <div className="info-text">
            <Notice />
          </div>
        </div>
        <div className="terms">
          <div className="agr-wrap">
            <p className="description">建築情報マップは、確認申請時、不動産取引時に必要な情報（都市計画情報を除く）を取りまとめた地図です。建築協定地区、宅地造成工事規制区域などの指定状況を調べることができます。</p>
            <p>&nbsp;</p>
            <p>下記、利用条件のすべてに同意しますか。</p>
            <div className="btn-box">
              <button onClick={agree} className="agr-btn k-y btnmode">同意する</button>
              <button className="agr-btn n btnmode"><a href="https://www.city.nagoya.jp/jigyou/category/39-6-4-9-0-0-0-0-0-0.html">同意しない</a></button>
            </div>
          </div>
          <h2>建築情報マップに関する利用条件</h2>
          <ol>
            <li>建築情報マップ（以下「本図」という。）は、表示している内容を証明するものではありません。参考図としてご利用下さい。</li>
            <li>本図の背景に利用した地図は、最新の情報ではありませんので、現況と異なる場合があります。</li>
            <li>本図は随時更新し、予告なく内容を変更する場合があります。<strong className="red">最新の情報は所管課窓口にてご確認ください。</strong></li>
            <li>本図の著作権は、名古屋市にあります。本図の一部又は全部を無断複写、複製、転載、ファイル化及び配布することを禁じます。</li>
            <li>本図の利用により発生した直接又は間接の損失・損害について、名古屋市は一切の責任を負いません。</li>
          </ol>
          <h2>表示情報に関する利用条件</h2>
          <ol>
            <li>表示情報は、<UpdateDate />現在のものです。</li>
            <li>表示情報は、地図の精度上及びデータの作成上の誤差を含んでいますので、詳細な境界位置は所管課窓口にてご確認ください。</li>
          </ol>
          <div className="agr-wrap">
            <p className="mt30">利用条件のすべてに同意しますか。</p>
            <div className="btn-box">
              <button onClick={agree} className="agr-btn k-y btnmode">同意する</button>
              <button className="agr-btn n btnmode"><a href="https://www.city.nagoya.jp/jigyou/category/39-6-4-9-0-0-0-0-0-0.html">同意しない</a></button>
            </div>
          </div>
        </div>
      </div>
      <SharedFooter />
    </div>
  );
}