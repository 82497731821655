import React from 'react';
import { Link } from 'react-router-dom';

export default class SharedFooter extends React.Component {

  constructor(props) {
    super(props);

    // refを作成
    this.spCheckRef = React.createRef();

    this.toggle = this.toggle.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.buttonClass = "";
    this.displayClass = "hide";
    this.state = { hide: true };
    // メイン画面のスクロールを復帰
    document.body.style.overflow = "visible";
  }

  toggle() {
    if (this.state.hide) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.buttonClass = "show";
    this.displayClass = "menu-open";
    this.setState({ hide: false });
    // メイン画面のスクロールを停止
    document.body.style.overflow = "hidden";
  }

  hide() {
    this.buttonClass = "";
    this.displayClass = "hide";
    this.setState({ hide: true });
    // メイン画面のスクロールを復帰
    document.body.style.overflow = "visible";
  }

  onClick(handler) {
    handler();
    this.hide();
  }

  componentDidMount () {
    // PCサイズに移行した場合にメニューが開いていたら閉じる
    const resizeObserver = new ResizeObserver(entries => {
      if (this.spCheckRef.current != null && this.spCheckRef.current.clientWidth == 0 && !this.state.hide) {
        this.hide();
      }
    });
    resizeObserver.observe(this.spCheckRef.current);
  }

  render() {
    const displayClassName = "sp-menu " + this.displayClass;
    const buttonClassName = "menu-button btnmode " + this.buttonClass;

    return (
      <header id="header">
        <div className="sp-check" ref={this.spCheckRef}></div>
        <div className="k-t-header">
          <div className="k-logo-bg">
            <div className="k-logo"><Link to="/kenchiku/" title="トップに戻る"></Link></div></div>
          <nav className="k-top-nav pc">
            <ul className="k-t-nav k-t-nav-top">
              <Link to="/kenchiku/dousa"><li>動作環境</li></Link>
              <Link to="/kenchiku/manual"><li>操作説明</li></Link>
              <Link to="/kenchiku/faq"><li>疑問・質問</li></Link>
            </ul>
          </nav>
          <div className="hb_menu">
            <div onClick={this.toggle} className={buttonClassName}> <span></span>  <span></span>  <span></span> </div>
          </div>
        </div>
        <div className={displayClassName}>
          <div className="k-menu-bar">メニュー</div>
          <nav className="s-top-navi">
            <ul className="s-top-nav">
              <li onClick={this.hide}><Link to="/kenchiku/">トップページ</Link></li>
              <li onClick={this.hide}><Link to="/kenchiku/dousa">動作環境</Link></li>
              <li onClick={this.hide}><Link to="/kenchiku/manual">操作説明</Link></li>
              <li onClick={this.hide}><Link to="/kenchiku/faq">疑問・質問</Link></li>
            </ul>
          </nav>
          <div className="k-menu-bar">関連リンク</div>
          <nav className="s-top-navi">
            <ul className="s-top-nav">
              <li><Link to="/nagoya/">名古屋市指定道路図</Link></li>
              <li><a href="https://www.douroninteizu.city.nagoya.jp/" target="_blank">名古屋市道路認定図</a></li>
              <li><a href="https://www2.wagmap.jp/nagoya-tokei/" target="_blank">名古屋市都市計画情報提供サービス</a></li>
              <li><a href="https://www.city.nagoya.jp/jigyou/category/39-6-4-9-0-0-0-0-0-0.html" target="_blank">建築情報マップの概要</a></li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }

}