import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { URL } from '../../url';

interface MapState {
  longitude: number;
  latitude: number;
  zoom: number;
}

const initialState: MapState = {
  longitude: 136.90545996823434,
  latitude: 35.18325139258121,
  zoom: 15,
}

const stateSlice = createSlice({
  name: 'map_state',
  initialState,
  reducers: {
    setMapState: (state, action: PayloadAction<MapState>) => {
      state.longitude = action.payload.longitude
      state.latitude = action.payload.latitude
      state.zoom = action.payload.zoom
    }
  }
})

export const { setMapState } = stateSlice.actions

export default stateSlice.reducer
