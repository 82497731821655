import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import SharedHeader from './components/kenchiku/SharedHeader';
import SharedFooter from './components/kenchiku/SharedFooter';

export const KenchikuFaq = (props) => {
  useEffect(() => {
    // タイトルをセット
    document.title = props.title;
  }, []);

  return (
    <div id="wrapper">
      <SharedHeader />
      <div className="container2">
        <h1>疑問・質問</h1>
        <div className="gry-wrap">
          <div className="faq-wrap">
            <div className="faq-tab">
              <input id="tab-uno1" type="checkbox" className="toggle" />
              <label htmlFor="tab-uno1" className="faq-title btnmode"><p className="faq-title-q">「同意する」ボタンをクリックしても動かない。</p>
              </label>
              <div className="faq-content">
                <p className="faq-content-a" >ご使用のブラウザが対応していない可能性があります。「動作環境ページ」の「対応ブラウザ」欄をご確認ください。</p>
              </div>
            </div>
            <div className="faq-tab">
              <input id="tab-uno2" type="checkbox" className="toggle" />
              <label htmlFor="tab-uno2" className="faq-title btnmode"><p className="faq-title-q">名古屋市以外の地図が表示されない。</p>
              </label>
              <div className="faq-content">
                <p className="faq-content-a" >名古屋市建築情報マップは、名古屋市における建築協定地区などの指定状況を明示した地図です。そのため、市域外は表示されません。</p>
              </div>
            </div>
            <div className="faq-tab">
              <input id="tab-uno3" type="checkbox" className="toggle" />
              <label htmlFor="tab-uno3" className="faq-title btnmode"><p className="faq-title-q">建築物の敷地が表示地区の内外にわたる場合の取り扱いは?</p>
              </label>
              <div className="faq-content">
                <p className="faq-content-a" >それぞれの所管課窓口にてご確認ください。</p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <SharedFooter />
    </div>
  );
}
