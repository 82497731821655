import React from 'react';
import { Link } from 'react-router-dom';

export default class SharedFooter extends React.Component {

  render() {
    return (
      <footer>
        <div className="f-img"></div>
        <div className="footer-wrap">
          <div className="footer-flex">
            <div className="footer-flex-item1">
              <p className="ma">名古屋市住宅都市局<br />
                建築指導部建築指導課</p>
              <p className="add">開庁時間:月曜日から金曜日 8時45分から17時15分まで<br />(祝日・休日・年末年始を除く)<br />
                〒460-8508　名古屋市中区三の丸三丁目1番1号<br />
                電話番号：052-972-2928 ファックス番号：052-972-4159 </p>
              <p className="copy">Copyright © Nagoya City Building Inspection Division All rights reserved.</p>
            </div>
            <div className="footer-flex-item2 pc">
              <nav className="footer-nav">
                <ul className="f-nav">
                  <li><Link to="/nagoya/dousa">動作環境</Link></li>
                  <li><Link to="/nagoya/manual">操作説明</Link></li>
                  <li><Link to="/nagoya/faq">疑問・質問</Link></li>
                </ul>
              </nav>
              <div className="link-wrap">
                <p>関連リンク</p>
                <ul className="link">
                  <li><Link to="/kenchiku/">建築情報マップ</Link></li>
                  <li><a href="https://www.douroninteizu.city.nagoya.jp/" target="_blank">名古屋市道路認定図</a></li>
                  <li><a href="https://www2.wagmap.jp/nagoya-tokei/" target="_blank">名古屋市都市計画情報提供サービス</a></li>
                  <li><a href="https://www.city.nagoya.jp/jutakutoshi/page/0000024541.html" target="_blank">指定道路図の概要</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }

}