import React from 'react';
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";

export default class UsageGuide extends React.Component {

  constructor(props) {
    super(props);

    // refを作成
    this.boxRef = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.resetPosition = this.resetPosition.bind(this);

    this.state = {
      hide: false,
      componentPosition: { x: 0, y: 0 }
    };

    this.displayClass = "";
  }

  toggle() {
    if (this.state.hide) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.displayClass = "popup-usage";
    this.setState({ hide: false });
    this.resetPosition();
  }

  hide() {
    this.displayClass = "hide";
    this.setState({ hide: true });
  }

  onDrag(e, position) {
    const {x, y} = position;
    this.setState({ position: {x, y} });
  }

  resetPosition() {
    this.setState({ position: {x: 0, y: 0} });
    this.boxRef.current.style.transform = "translate(0, 0)";
  }

  componentDidMount () {
    const resizeObserver = new ResizeObserver(entries => {
      if (this.boxRef.current != null && this.boxRef.current.clientWidth != 210) {
        this.resetPosition();
      }
    });
    resizeObserver.observe(this.boxRef.current);
  }

  render() {
    const className = "mk-w " + this.displayClass;

    return (
      <Draggable handle=".handle" position={this.state.positon} onDrag={this.onDrag}>
      <div id="usageBox" className={className} ref={this.boxRef}>
        <div className="mk-in">
          <div className="close-btn btnmode" onClick={this.toggle}></div>
          <div className="handle"><p className="mk-title">凡例</p></div>
          <div className="mk-box">
            <div className="mk-left">
              <p className="mk-text">■道路の種別</p>
              <ul className="map-key">
                <li><div className="map-k icon1"></div>1号道路</li>
                <li><div className="map-k icon2"></div>2号道路</li>
                <li><div className="map-k icon3"></div>3号道路</li>
                <li><div className="map-k icon4"></div>5号道路</li>
                <li><div className="map-k icon5"></div>2項道路</li>
              </ul>
            </div>
            <div className="mk-right">
              <p className="mk-text">■その他</p>
              <ul className="map-key2">
                <li><div className="map-k icon7"></div>土地区画整理事業施工中</li>
                <li><div className="map-k icon8"></div><span>特定通路のうち<br />許可実績等のあるもの<br /><span className="s-txt">(建築基準法の道路ではありません)</span></span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      </Draggable>
    );
  }

}
