import React from 'react';

export default class SearchBox extends React.Component {

  constructor(props) {
    super(props);
    // refを作成
    this.keywordRef = React.createRef();

    this.search = this.search.bind(this);
    this.request = this.request.bind(this);
    this.changeKeywordValue = this.changeKeywordValue.bind(this);
    this.keydownHandler = this.keydownHandler.bind(this);
    this.hideResult = this.hideResult.bind(this);

    this.bgClass = "hide";
    this.state = { resultList: null };
  }

  search() {
    const keyword = this.keywordRef.current.value;
    if (keyword != "") {
      this.setBackGround(true);
      this.request(keyword);
    } else {
      this.setBackGround(false);
      this.setResultList(null);
    }
  }

  request(keyword: string) {
    let url = "https://msearch.gsi.go.jp/address-search/AddressSearch";
    url += "?q=" + encodeURI(keyword);

    const request = new Request(url, {
      method: "GET",
    });
    // 名古屋市内の市区町村コード
    const addressCodes = [
      "23101",
      "23102",
      "23103",
      "23104",
      "23105",
      "23106",
      "23107",
      "23108",
      "23109",
      "23110",
      "23111",
      "23112",
      "23113",
      "23114",
      "23115",
      "23116"
    ];

    const parent = this;
    let searchResult = [];
    fetch(request).then(function (response) {
      if (response.ok) {
        response.json().then(function (json) {
          for (const value of json) {
            if (addressCodes.includes(value.properties.addressCode) || 
              (value.properties.title.indexOf("名古屋市") != -1 )
            ){
              // セットされた情報が5件の場合処理を終了
              if (searchResult.length === 5) {
                break;
              }
              // 名古屋市内の情報
              // console.log(json[key].properties.addressCode);
              searchResult.push({
                title: value.properties.title,
                lon: value.geometry.coordinates[0],
                lat: value.geometry.coordinates[1]
              });
            }
          }
          let list = <div className="results"><p>該当する場所はありません</p></div>;
          if (searchResult.length > 0){
            const results =  searchResult.map((searchResult, index) =>
              <div key={index} className="btnmode" onClick={parent.selectLocation.bind(parent, searchResult.lon, searchResult.lat, searchResult.title)}>{searchResult.title}</div>
            );
            list = <div className="results">{results}</div>
          }
          parent.setResultList(list);
          parent.props.linkage.current.setResultList(list);
        });
      }
    });
  }

  setResultList(list) {
    this.setState({ resultList: list });
  }

  selectLocation(this, lon: number, lat: number, title: string) {
    this.props.map.setCenter([lon, lat]);
    this.setBackGround(false);
    this.setResultList(null);
    this.props.linkage.current.setResultList(null);
    this.keywordRef.current.value = title;
    this.props.linkage.current.keywordRef.current.value = title;
  }

  changeKeywordValue(e) {
    this.props.linkage.current.keywordRef.current.value = e.target.value;
  }

  keydownHandler(e) {
    if (e.keyCode == 13) {
      this.search();
    }
  }

  hideResult() {
    this.setBackGround(false);
    this.setResultList(null);
    this.props.linkage.current.setResultList(null);
  }

  setBackGround(show) {
    const value: String = (show ? "" : "hide");
    this.bgClass = value;
    this.props.linkage.current.bgClass = value;
  }

  render() {
    const bgClassName = "search-bg " + this.bgClass;

    return (
    <div>
      <div className={bgClassName} onClick={this.hideResult}></div>
      <div className="search">
        <input type="text" className="searchTerm" ref={this.keywordRef} onChange={this.changeKeywordValue} onKeyDown={this.keydownHandler} placeholder="住所・キーワードを入力（複数キーワード不可）" />
        <button type="button" className="searchButton" onClick={this.search}><i className="fa fa-search"></i></button>
      </div>
      {this.state.resultList}
    </div>
    );
  }

}