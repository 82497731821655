import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as MapLibre from 'maplibre-gl';
import { useAppSelector, useAppDispatch } from '../hook';
import { RootState } from '../store'
import { setMapState } from '../features/map/map_state';

import Navigation from './components/shiteidouro/Navigation';
import UsageGuide from './components/shiteidouro/UsageGuide';
import MapSelect from './components/shiteidouro/MapSelect';
import SpMenu from './components/shiteidouro/SpMenu';
import SearchBox from './components/shiteidouro/SearchBox';
import { URL } from '../url';

export const Shiteidouro = (props) => {
  const [map, setMap] = useState<MapLibre.Map>(null);
  const style_json = URL + '/styles/shiteidouro-style/style.json';
  const target_buffer = 10;
  const dispatch = useAppDispatch()
  const longitude = useAppSelector((state: RootState) => state.mapState.longitude)
  const latitude = useAppSelector((state: RootState) => state.mapState.latitude)
  const zoom = useAppSelector((state: RootState) => state.mapState.zoom)

  const history = useHistory();

  useEffect(() => {
    // タイトルをセット
    document.title = props.title;
  }, []);

  useEffect(() => {
    const getParam = (i, k) => (i.find(i => i.split('=')[0] === k) || '').split('=')[1] || '';
    if (!getParam(document.cookie.split(/;\s*/), 'shiteidouro_agree')) {
      history.push("/nagoya/");
    }

    setMap(new MapLibre.Map({
      container: 'map',
      style: style_json,
      center: [longitude, latitude],
      zoom: zoom,
      maxZoom: 17,
      minZoom: 14,
      preserveDrawingBuffer: true,
      dragRotate: false,
      hash: true,
    }));
  }, []);

  useEffect(() => {
    if (map === null) {
      return
    }
    dispatch(setMapState(showMapView()))

    // ナビゲーションコントロールを配置（左上）
    map.addControl(new MapLibre.NavigationControl({ showCompass: false }), 'top-left');
    // フルスクリーンコントロールを設置（右上）
    map.addControl(new MapLibre.FullscreenControl({ container: document.querySelector('body') }), 'top-right');
    // タッチ操作での回転を無効に設定
    map.touchZoomRotate.disableRotation();

    const layers = [
      'shiteidouro_2gou_pl_web',
      'shiteidouro_5gou_pl_web',
    ];
    map.on('mousemove', (e) => {
      const bbox = [
          [e.point.x - (target_buffer / 2), e.point.y - (target_buffer / 2)],
          [e.point.x + (target_buffer / 2), e.point.y + (target_buffer / 2)]
      ];
      const features = map.queryRenderedFeatures(bbox, {layers});
      if (features.length === 0) {
        map.getCanvas().style.cursor = '';
      } else {
        map.getCanvas().style.cursor = 'pointer';
      }
    });

    map.on('click', (e) => {
      const bbox = [
        [e.point.x - (target_buffer / 2), e.point.y - (target_buffer / 2)],
        [e.point.x + (target_buffer / 2), e.point.y + (target_buffer / 2)]
      ];
      const features = map.queryRenderedFeatures(bbox, { layers });
      if (features.length) {
        map.getCanvas().style.cursor = '';

        const roadNum: string = features[0].layer.id.match(/^shiteidouro_(\d+)gou_pl_web$/)[1];
        const prop: object = features[0].properties;
        if (roadNum == '2') {
          prop['ymd'] = {label: '許可年月日', value: prop.kyoka_ymd || ''};
          prop['number'] = {label: '許可番号', value: prop.kyoka_ban || ''};
        } else {
          prop['ymd'] = {label: '指定年月日', value: prop.shitei_ymd || ''};
          prop['number'] = {label: '指定番号', value: prop.shitei_ban || ''};
        }
        let htmlString = ` 
<p class="pop-title">${roadNum || ''}号道路（整理番号 ${prop.daicyo_ban || ''}）</p>
<div class="detail">
<dl>
<dt>${prop.ymd.label || ''}</dt><dd>${prop.ymd.value || ''}</dd>
<dt>${prop.number.label || ''}</dt><dd>${prop.number.value || ''}</dd>
<dt>幅員</dt><dd>${prop.fukuin || ''}</dd>
<dt>延長</dt><dd>${prop.encyo || ''}</dd>
</dl>
</div>
        `;
        if (typeof prop.filename !== "undefined") {
          const url: String = "/pdf/" + prop.filename;
          htmlString += `<div class="dtl-btn-box"><button class="dtl-btn btnmode" onclick="window.open('${url}', '_blank');">指定道路調書</button></div>`;
        }
        map.getCanvas().style.cursor = 'pointer';
        new MapLibre.Popup({ maxWidth: '320px' })
          .setLngLat(e.lngLat)
          .setHTML(htmlString)
          .addTo(map);
      } else {
        map.getCanvas().style.cursor = 'pointer';
      }
    });
    map.on('moveend', (e) => {
      dispatch(setMapState(showMapView()))
    });

  }, [map])

  // 子コンポーネント（凡例）にアクセスするためRefを作成
  const usageBoxRef = React.createRef();
  // 子コンポーネント（凡例）内のtoggle関数を実行
  const usageBoxToggle = () => {
    usageBoxRef.current.toggle();
  }
  // 子コンポーネント（地図選択）にアクセスするためRefを作成
  const mapSelectRef = React.createRef();
  // 子コンポーネント（地図選択）内のtoggle関数を実行
  const mapSelectToggle = () => {
    mapSelectRef.current.toggle();
  }
  // 子コンポーネント（スマホ用メニュー）にアクセスするためRefを作成
  const spMenuRef = React.createRef();
  // 子コンポーネント（スマホ用メニュー）内のtoggle関数を実行
  const spMenuButtonClick = (e) => {
    spMenuRef.current.toggle();
  }
  const searchBoxRef1 = React.createRef();
  const searchBoxRef2 = React.createRef();

  const showMapView = () => {
    return {
      longitude: map.getCenter().lng,
      latitude: map.getCenter().lat,
      zoom: map.getZoom()
    }
  }

  return (
    <div id="wrapper2">
      <header id="header">
        <div className="m-header">
          <div className="mappage-logo"><Link to="/nagoya/" title="トップページにもどる"></Link></div>
          <div className="search-box tb">
            <SearchBox ref={searchBoxRef1} map={map} linkage={searchBoxRef2} />
          </div>
          <Navigation usageBoxHandler={usageBoxToggle} mapSelectHandler={mapSelectToggle} />
          <div className="hb_menu" onClick={spMenuButtonClick}>
            <div className="menu-button btnmode"><span></span><span></span><span></span></div>
          </div>
        </div>
        <div className="sp-nav">
          <div className="search-box sp">
            <SearchBox ref={searchBoxRef2} map={map} linkage={searchBoxRef1} />
          </div>
          <SpMenu ref={spMenuRef} usageBoxHandler={usageBoxToggle} mapSelectHandler={mapSelectToggle} />
        </div>
      </header>
      <div className="m-container">
        <div id="map"></div>
        <div id="layer2"></div>
        <UsageGuide ref={usageBoxRef} />
        <MapSelect ref={mapSelectRef} />
      </div>
    </div>
  );
}
