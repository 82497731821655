import React, { useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import * as MapLibre from 'maplibre-gl'
import dayjs from 'dayjs'
import { useAppSelector } from '../hook'
import { RootState } from '../store'

import UpdateDate from './components/shiteidouro/UpdateDate';
import { URL } from '../url';

export const ShiteidouroPrint = () => {
  const output_time = dayjs().format('YYYY年MM月DD日')
  const [map, setMap] = useState<MapLibre.Map>(null)
  const style_json = URL + '/styles/shiteidouro-style/style.json';
  const longitude = useAppSelector((state: RootState) => state.mapState.longitude)
  const latitude = useAppSelector((state: RootState) => state.mapState.latitude)
  const zoom = useAppSelector((state: RootState) => state.mapState.zoom)

  const history = useHistory();

  useEffect(() => {
    const getParam = (i, k) => (i.find(i => i.split('=')[0] === k) || '').split('=')[1] || '';
    if (!getParam(document.cookie.split(/;\s*/), 'shiteidouro_agree')) {
      history.push("/nagoya/");
    }

    setMap(new MapLibre.Map({
      container: "map_print",
      style: style_json,
      center: [longitude, latitude],
      zoom: zoom,
      maxZoom: 17,
      minZoom: 14,
      preserveDrawingBuffer: true,
      dragRotate: false,
      hash: true,
    }))
  }, [])

  useEffect(() => {
    if (map === null) {
      return
    }
    // タッチ操作での回転を無効に設定
    map.touchZoomRotate.disableRotation();
    // 画面のスクロールを復帰
    document.body.style.overflow = "visible";
  }, [map])

  return (
    <div className="print-bg">
      <div className="header">
        <div className="print-button">
          <button className="btnmode y mr10" onClick={() => window.print()}>印刷</button>
          <button className="btnmode n" onClick={() => history.go(-1)}>元の画面に戻る</button>
          <p>
            プリンターのプロパティで用紙サイズにA4、向きを横に設定して印刷してください。<br />
            なお、地図はこのページでもスクロールできます。
          </p>
        </div>
      </div>
      <div className="print-frame">
        <div className="print">
          <div className="output_time">
            出力日: {output_time}
          </div>
          <div className="title">
            名古屋市指定道路図
          </div>
          <div className="map_container">
            <div id="map_print"></div>
            <div className="hanrei">
              <img src={require("../assets/img/shiteidouro/print_hanrei.png")} />
            </div>
          </div>
          <div className="note">
            ※注意
            <ol>
              <li>指定道路図（以下「本図」という。）は、表示している内容を証明するものではありません。参考図としてご利用ください。</li>
              <li>本図は、建築基準法の道路種別のみを表示したものであり、道路の幅員・境界位置、始終端位置などの道路の形状を示すものではありません。</li>
              <li>本図の背景に利用した地図は、最新の情報ではありませんので、現況と異なる場合があります。</li>
              <li>本図は随時更新し、予告なく内容を変更する場合があります。最新の情報は建築指導課窓口にてご確認ください。</li>
              <li>道路情報は、<UpdateDate />現在のものです。</li>
              <li>現状と道路種別に相違がある場合、疑義等がある場合は、建築指導課窓口までお問い合わせください。</li>
            </ol>
          </div>
          <div className="footer">名古屋市住宅都市局建築指導部建築指導課</div>
        </div>
      </div>
    </div>
  );
}