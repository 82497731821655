import React from 'react';
import { Link } from 'react-router-dom';

export default class Navigation extends React.Component {

  onClick(handler) {
    handler();
  }

  render() {
    return (
      <nav className="top-nav pc">
        <ul className="t-nav">
          <li className="btnmode" onClick={this.onClick.bind(this, this.props.mapSelectHandler)}><a><div className="nav-icon nav-icon1"></div><span className="nav-icon-txt">地図選択</span></a></li>
          <Link to="/nagoya/manual" target="_blank"><li className="btnmode"><div className="nav-icon nav-icon2"></div><span className="nav-icon-txt">操作説明</span></li></Link>
          <li className="btnmode" onClick={this.onClick.bind(this, this.props.usageBoxHandler)}><a><div className="nav-icon nav-icon3"></div><span className="nav-icon-txt">凡例</span></a></li>
          <Link to="/nagoya/print"><li className="btnmode"><div className="nav-icon nav-icon4"></div><span className="nav-icon-txt">印刷</span></li></Link>
        </ul>
      </nav>
    );
  }

}