import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as MapLibre from 'maplibre-gl';
import { useAppSelector, useAppDispatch } from '../hook';
import { RootState } from '../store'
import { setMapState } from '../features/map/map_state';

import Navigation from './components/kenchiku/Navigation';
import UsageGuide from './components/kenchiku/UsageGuide';
import MapSelect from './components/kenchiku/MapSelect';
import SpMenu from './components/kenchiku/SpMenu';
import SearchBox from './components/kenchiku/SearchBox';
import { URL } from '../url';

export const Kenchiku = (props) => {
  const [map, setMap] = useState<MapLibre.Map>(null);
  const style_json = URL + '/styles/kenchiku-style/style.json';
  const target_buffer = 0;
  const dispatch = useAppDispatch()
  const longitude = useAppSelector((state: RootState) => state.mapState.longitude)
  const latitude = useAppSelector((state: RootState) => state.mapState.latitude)
  const zoom = useAppSelector((state: RootState) => state.mapState.zoom)

  const history = useHistory();

  useEffect(() => {
    // タイトルをセット
    document.title = props.title;
  }, []);

  useEffect(() => {
    const getParam = (i, k) => (i.find(i => i.split('=')[0] === k) || '').split('=')[1] || '';
    if (!getParam(document.cookie.split(/;\s*/), 'kenchiku_agree')) {
      history.push("/kenchiku/");
    }

    setMap(new MapLibre.Map({
      container: 'map',
      style: style_json,
      center: [longitude, latitude],
      zoom: zoom,
      maxZoom: 16,
      minZoom: 14,
      preserveDrawingBuffer: true,
      dragRotate: false,
      hash: true,
    }));
  }, []);

  useEffect(() => {
    if (map === null) {
      return
    }
    dispatch(setMapState(showMapView()))

    // ナビゲーションコントロールを配置（左上）
    map.addControl(new MapLibre.NavigationControl({ showCompass: false }), 'top-left');
    // フルスクリーンコントロールを設置（右上）
    map.addControl(new MapLibre.FullscreenControl({ container: document.querySelector('body') }), 'top-right');
    // タッチ操作での回転を無効に設定
    map.touchZoomRotate.disableRotation();

    const layers = [
      'danchinintei_pg',
      'kenchikukyoutei_pg',
      'machinamihozon_pg',
      'rinkaibubousai_dai1syu_pg',
      'rinkaibubousai_dai2syu_pg',
      'rinkaibubousai_dai3syu_pg',
      'rinkaibubousai_dai4syu_pg',
      'takuchizousei_koujikisei_pg',
      'tochikukakuseirijigyo_koukyou_pg',
      'tochikukakuseirijigyo_kumiai_pg',
      'toshikeikan_keisei_pg',
      'toshikeikan_kyoutei_pg',
      'kenchiku_mokuzo_pg',
    ];
    map.on('mousemove', (e) => {
      const bbox = [
          [e.point.x - (target_buffer / 2), e.point.y - (target_buffer / 2)],
          [e.point.x + (target_buffer / 2), e.point.y + (target_buffer / 2)]
      ];
      const features = map.queryRenderedFeatures(bbox, {layers});
      if (features.length === 0) {
        map.getCanvas().style.cursor = '';
      } else {
        map.getCanvas().style.cursor = 'pointer';
      }
    });

    map.on('click', (e) => {
      const bbox = [
        [e.point.x - (target_buffer / 2), e.point.y - (target_buffer / 2)],
        [e.point.x + (target_buffer / 2), e.point.y + (target_buffer / 2)]
      ];
      const features = map.queryRenderedFeatures(bbox, { layers });
      if (features.length) {
        map.getCanvas().style.cursor = '';
        
        let htmlString: String = `<ul class="k-popup-list">`;
        Object.keys(features).forEach((key) => {
          const prop: object = features[key].properties;

          switch (features[key].layer.id) {
            case 'danchinintei_pg': // 団地認定
              htmlString += `<li><a href="${prop.linkpath || ''}" target="_blank">${prop.title || ''}（${prop.danchiname || ''}、台帳番号 ${prop.daichono || ''}）</a></li>`;
              break;
            case 'kenchikukyoutei_pg': // 建築協定地区
            case 'machinamihozon_pg': // 町並み保存地区
            case 'rinkaibubousai_dai1syu_pg': // 第1種臨海部防災区域
            case 'rinkaibubousai_dai2syu_pg': // 第2種臨海部防災区域
            case 'rinkaibubousai_dai3syu_pg': // 第3種臨海部防災区域
            case 'rinkaibubousai_dai4syu_pg': // 第4種臨海部防災区域
            case 'toshikeikan_keisei_pg': // 都市景観形成地区等
            case 'toshikeikan_kyoutei_pg': // 都市景観協定地区等
            case 'kenchiku_mokuzo_pg': // 木造住宅密集地域
              htmlString += `<li><a href="${prop.gaiyo || ''}" target="_blank">${prop.chiku || ''}</a></li>`;
              break;
            case 'takuchizousei_koujikisei_pg': // 宅地造成工事規制区域
              htmlString += `<li>宅地造成工事規制区域</li>`;
              break;
            case 'tochikukakuseirijigyo_koukyou_pg': // 土地区画整理事業区域（市）
              htmlString += `<li>公共団体施行土地区画整理事業<br /><a href="${prop.gaiyo || ''}" target="_blank">${prop.chiku || ''}</a></li>`;
              break;
            case 'tochikukakuseirijigyo_kumiai_pg': // 土地区画整理事業区域（組合）
              htmlString += `<li>組合施行土地区画整理事業<br /><a href="${prop.gaiyo || ''}" target="_blank">${prop.chiku || ''}</a></li>`;
              break;
          }
        });
        htmlString += `</ul>`;

        map.getCanvas().style.cursor = 'pointer';
        new MapLibre.Popup({ maxWidth: '320px' })
          .setLngLat(e.lngLat)
          .setHTML(htmlString)
          .addTo(map);
      } else {
        map.getCanvas().style.cursor = 'pointer';
      }
    });
    map.on('moveend', (e) => {
      dispatch(setMapState(showMapView()))
    });

  }, [map])

  // 子コンポーネント（凡例）にアクセスするためRefを作成
  const usageBoxRef = React.createRef();
  // 子コンポーネント（凡例）内のtoggle関数を実行
  const usageBoxToggle = () => {
    usageBoxRef.current.toggle();
  }
  // 子コンポーネント（地図選択）にアクセスするためRefを作成
  const mapSelectRef = React.createRef();
  // 子コンポーネント（地図選択）内のtoggle関数を実行
  const mapSelectToggle = () => {
    mapSelectRef.current.toggle();
  }
  // 子コンポーネント（スマホ用メニュー）にアクセスするためRefを作成
  const spMenuRef = React.createRef();
  // 子コンポーネント（スマホ用メニュー）内のtoggle関数を実行
  const spMenuButtonClick = (e) => {
    spMenuRef.current.toggle();
  }
  const searchBoxRef1 = React.createRef();
  const searchBoxRef2 = React.createRef();

  const showMapView = () => {
    return {
      longitude: map.getCenter().lng,
      latitude: map.getCenter().lat,
      zoom: map.getZoom()
    }
  }

  return (
    <div id="wrapper2">
      <header id="header">
        <div className="k-m-header">
          <div className="k-mappage-logo-bg">
            <div className="k-mappage-logo"><Link to="/kenchiku/" title="トップページにもどる"></Link></div></div>
          <div className="k-search-box tb">
            <SearchBox ref={searchBoxRef1} map={map} linkage={searchBoxRef2} />
          </div>
          <Navigation usageBoxHandler={usageBoxToggle} mapSelectHandler={mapSelectToggle} />
          <div className="hb_menu" onClick={spMenuButtonClick}>
            <div className="menu-button btnmode"><span></span><span></span><span></span></div>
          </div>
        </div>
        <div className="sp-nav">
          <div className="k-search-box sp">
            <SearchBox ref={searchBoxRef2} map={map} linkage={searchBoxRef1} />
          </div>
          <SpMenu ref={spMenuRef} usageBoxHandler={usageBoxToggle} mapSelectHandler={mapSelectToggle} />
        </div>
      </header>
      <div className="m-container kenchiku">
        <div id="map"></div>
        <div id="layer2"></div>
        <UsageGuide ref={usageBoxRef} />
        <MapSelect ref={mapSelectRef} />
      </div>
    </div>
  );
}
