import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import SharedHeader from './components/shiteidouro/SharedHeader';
import SharedFooter from './components/shiteidouro/SharedFooter';

export const ShiteidouroDousa = (props) => {
  useEffect(() => {
    // タイトルをセット
    document.title = props.title;
  }, []);

  return (
    <div id="wrapper">
      <SharedHeader />
      <div className="container2">
        <h1>動作環境について</h1>
        <div className="gry-wrap">
          <div className="white-wrap">
            <h2>対応ブラウザ</h2>
            <p>Microsoft Edge バージョン95.0、FireFox バージョン94.0、Google Chrome バージョン95.0にて動作確認を行っています。<br />上記以外のブラウザでは、正しく動作しない可能性があります。</p>
            <h2>回線速度</h2>
            <p>地図データを利用した情報提供を行っていますので、通常のページに比べ送信データサイズが大きくなっています。<br />回線速度やご利用機器の性能によってページの表示に時間がかかる場合があります。</p>
          </div>
        </div>
      </div>
      <SharedFooter />
    </div>
  );
}
