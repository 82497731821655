import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import SharedHeader from './components/kenchiku/SharedHeader';
import SharedFooter from './components/kenchiku/SharedFooter';

export const KenchikuManual = (props) => {
  useEffect(() => {
    // タイトルをセット
    document.title = props.title;
  }, []);

  return (
    <div id="wrapper" className="manual">
      <SharedHeader />
      <div className="container2">
        <h1>建築情報マップの操作説明</h1>
        <div className="gry-wrap">
          <div className="white-wrap">
          {/* ↓↓ここにに内容を記載↓↓ */}
          <h2>地図を拡大・縮小または移動する</h2>
          <p>左上の+と-ボタン、またはマウスホイールを使用して、地図の拡大縮小ができます。また、マウスをドラックすることで地図を移動させることができます。</p>
          <img src="/images/sousa_k1.png" alt="地図を拡大・縮小または移動する説明画像" />
          <h2>調べたい場所を検索する</h2>
          <p>調べたい場所に移動してみましょう。検索窓に住所やランドマーク施設を入力して検索すると、周辺の地図が表示されます。</p>
          <img src="/images/sousa_k2.png" alt="調べたい場所を検索する説明画像" />
          <ol>
          <li type="1">検索窓に調べたい住所などを入力</li>
          <li type="1">検索結果から該当のものを選択</li>
          <li type="1">2で選択した場所が表示されます</li>
          </ol>
          <h2>指定状況を調べる</h2>
          <p>建築協定地区などが指定されている場所には色塗りがされています。調べたい場所で地図をクリックすると指定状況の概要を表示することができます。</p>
          <img src="/images/sousa_k3.png" alt="指定状況を調べる説明画像" />
          <ol>
          <li type="1">調べたい場所をクリック<br />指定状況が確認できる場所はマウスカーソルが指差しに変わります</li>
          <li type="1">指定状況が表示されます</li>
          <li type="1">地区名をクリックすると説明ページに移動します</li>
          </ol>
          <h2>地図を印刷する</h2>
          <p>表示している地図を印刷してみましょう。印刷ボタンをクリックすると表示している場所の地図を印刷することができます。<br />
          プリンターのプロパティで用紙サイズにA4、向きを横に設定して印刷してください。</p>
          <img src="/images/sousa_k4.png" alt="地図を印刷する説明画像" />
          <ol>
          <li type="1">印刷ボタンをクリック</li>
          <li type="1">印刷画面で印刷ボタンをクリックすると印刷ダイアログが開きます<br />地図はドラッグ操作で場所の移動、拡大縮小ができます</li>
          </ol>
          {/* ↑↑ここにに内容を記載↑↑ */}
          </div>
        </div>
      </div>
      <SharedFooter />
    </div>
  );
}
