import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import SharedHeader from './components/shiteidouro/SharedHeader';
import SharedFooter from './components/shiteidouro/SharedFooter';
import UpdateDate from './components/shiteidouro/UpdateDate';
import Notice from './components/shiteidouro/Notice';

export const ShiteidouroTop = (props) => {
  const history = useHistory();

  useEffect(() => {
    // タイトルをセット
    document.title = props.title;
  }, []);

  const agree = () => {
    // document.cookie = "shiteidouro_agree=true;path=/;secure=true;SameSite=none";
    document.cookie = "shiteidouro_agree=true;path=/"; // 非SSL環境用
    history.push("/nagoya/nagoya_shiteidouro");
  }

  return (
    <div id="wrapper">
      <SharedHeader />
      <div className="container">
        <div className="info-box">
          <h1 className="info-title">お知らせ</h1>
          <div className="info-text">
            <Notice />
          </div>
        </div>
        <div className="terms">
          <div className="agr-wrap">
            <p className="mb40">指定道路図は建築基準法上の<br className="sp" />道路の種別を明示した地図です。</p>
            <p>&nbsp;</p>
            <p>下記、利用条件のすべてに同意しますか。</p>
            <div className="btn-box">
              <button onClick={agree} className="agr-btn y btnmode">同意する</button>
              <button className="agr-btn n btnmode"><a href="https://www.city.nagoya.jp/jutakutoshi/page/0000024541.html">同意しない</a></button>
            </div>
          </div>
          <h2>指定道路図に関する利用条件</h2>
          <ol>
            <li>この指定道路図（以下「本図」という。）は、建築基準法施行規則（第10条の2）に規定する「指定道路に関する図面」ではありません。</li>
            <li>本図は、表示している内容を証明するものではありません。参考図としてご利用ください。</li>
            <li>本図は、建築基準法の道路種別のみを表示したものであり、<strong className="red">道路の幅員・境界位置、始終端位置などの道路の形状を示すものではありません。</strong>また、各敷地の接道状況を示すものではありません。</li>
            <li>本図の背景に利用した地図は、最新の情報ではありませんので、現況と異なる場合があります。</li>
            <li>本図は随時更新し、予告なく内容を変更する場合があります。<strong className="red">最新の情報は建築指導課窓口にてご確認ください。</strong></li>
            <li>本図の著作権は、名古屋市にあります。本図の一部又は全部を無断複写、複製、転載、ファイル化及び配布することを禁じます。</li>
            <li>本図の利用により発生した直接又は間接の損失・損害について、名古屋市は一切の責任を負いません。</li>
          </ol>
          <h2>道路情報に関する利用条件</h2>
          <ol>
            <li>道路情報は、<UpdateDate />現在のものです。</li>
            <li>道路として表示された路線でも現に存在しないものは、建築基準法上の道路として扱うことはできません。</li>
            <li>2号道路、5号道路で表示される幅員及び延長は、指定時のものです。現況幅員が指定幅員に満たない場合は、再整備が必要です。</li>
            <li>現況と道路情報に相違がある場合、疑義等がある場合は、建築指導課窓口までお問い合わせください。</li>
          </ol>
          <div className="agr-wrap">
            <p className="mt30">利用条件のすべてに同意しますか。</p>
            <div className="btn-box">
              <button onClick={agree} className="agr-btn y btnmode">同意する</button>
              <button className="agr-btn n btnmode"><a href="https://www.city.nagoya.jp/jutakutoshi/page/0000024541.html">同意しない</a></button>
            </div>
          </div>
        </div>
      </div>
      <SharedFooter />
    </div>
  );
}