import React from 'react';
import { Link } from 'react-router-dom';

export default class SpMenu extends React.Component {

  constructor(props) {
    super(props);

    // refを作成
    this.spCheckRef = React.createRef();

    this.toggle = this.toggle.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.displayClass = "hide";
    this.state = { hide: true };
    // メイン画面のスクロールを復帰
    document.body.style.overflow = "visible";
  }

  toggle() {
    if (this.state.hide) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    const buttonElement = document.querySelector('.menu-button');
    buttonElement.classList.add('show');
    this.displayClass = "menu-open";
    this.setState({ hide: false });
    // メイン画面のスクロールを停止
    document.body.style.overflow = "hidden";
  }

  hide() {
    const buttonElement = document.querySelector('.menu-button');
    buttonElement.classList.remove('show');
    this.displayClass = "hide";
    this.setState({ hide: true });
    // メイン画面のスクロールを復帰
    document.body.style.overflow = "visible";
  }

  onClick(handler) {
    handler();
    this.hide();
  }

  componentDidMount () {
    // PCサイズに移行した場合にメニューが開いていたら閉じる
    const resizeObserver = new ResizeObserver(entries => {
      if (this.spCheckRef.current != null && this.spCheckRef.current.clientWidth == 0 && !this.state.hide) {
        this.hide();
      }
    });
    resizeObserver.observe(this.spCheckRef.current);
  }

  render() {
    const className = "sp-menu " + this.displayClass;

    return (
      <div className={className}>
        <div className="sp-check" ref={this.spCheckRef}></div>
        <div className="k-top-bar"></div>
        <nav className="s-navi">
          <ul className="s-nav">
            <li className="btnmode" onClick={this.onClick.bind(this, this.props.mapSelectHandler)}><div className="nav-icon_s nav-icon_s1"></div>地図選択</li>
            <Link to="/kenchiku/manual" target="_blank"><li><div className="nav-icon_s nav-icon_s2"></div>操作説明</li></Link>
            <li className="btnmode" onClick={this.onClick.bind(this, this.props.usageBoxHandler)}><div className="nav-icon_s nav-icon_s3"></div>凡例</li>
            <Link to="/kenchiku/print"><li><div className="nav-icon_s nav-icon_s4"></div>印刷</li></Link>
          </ul>
        </nav>
      </div>
    );
  }

}