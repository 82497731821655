import React from 'react';
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";

export default class UsageGuide extends React.Component {

  constructor(props) {
    super(props);

    // refを作成
    this.boxRef = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.resetPosition = this.resetPosition.bind(this);

    this.state = {
      hide: false,
      componentPosition: { x: 0, y: 0 }
    };

    this.displayClass = "";
  }

  toggle() {
    if (this.state.hide) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.displayClass = "popup-usage";
    this.setState({ hide: false });
    this.resetPosition();
  }

  hide() {
    this.displayClass = "hide";
    this.setState({ hide: true });
  }

  onDrag(e, position) {
    const {x, y} = position;
    this.setState({ position: {x, y} });
  }

  resetPosition() {
    this.setState({ position: {x: 0, y: 0} });
    this.boxRef.current.style.transform = "translate(0, 0)";
  }

  componentDidMount () {
    const resizeObserver = new ResizeObserver(entries => {
      if (this.boxRef.current != null && this.boxRef.current.clientWidth != 210) {
        this.resetPosition();
      }
    });
    resizeObserver.observe(this.boxRef.current);
  }

  render() {
    const className = "k-mk-w " + this.displayClass;

    return (
      <Draggable handle=".handle" position={this.state.positon} onDrag={this.onDrag}>
        <div id="usageBox" className={className} ref={this.boxRef}>
          <div className="k-mk-in">
            <div className="k-close-btn btnmode" onClick={this.toggle}></div>
            <div className="handle"><p className="k-mk-title">凡例</p></div>
            <div className="k-mk-box">
              <div className="k-mk-left">
                <ul className="k-map-key">
                  <li><div className="k-map-k k-icon1"></div><a href="http://www.city.nagoya.jp/jigyou/category/39-6-3-5-3-2-0-0-0-0.html" target="_blank" title="建築協定地区">建築協定地区</a></li>
                  <li><div className="k-map-k k-icon2"></div><a href="http://www.city.nagoya.jp/jigyou/category/39-6-3-2-6-0-0-0-0-0.html" target="_blank" title="第1種臨海部防災区域">第1種臨海部防災区域</a></li>
                  <li><div className="k-map-k k-icon3"></div><a href="http://www.city.nagoya.jp/jigyou/category/39-6-3-2-6-0-0-0-0-0.html" target="_blank" title="第2種臨海部防災区域">第2種臨海部防災区域</a></li>
                  <li><div className="k-map-k k-icon4"></div><a href="http://www.city.nagoya.jp/jigyou/category/39-6-3-2-6-0-0-0-0-0.html" target="_blank" title="第3種臨海部防災区域">第3種臨海部防災区域</a></li>
                  <li><div className="k-map-k k-icon5"></div><a href="http://www.city.nagoya.jp/jigyou/category/39-6-3-2-6-0-0-0-0-0.html" target="_blank" title="第4種臨海部防災区域">第4種臨海部防災区域</a></li>
                  <li><div className="k-map-k k-icon6"></div><a href="http://www.city.nagoya.jp/jigyou/category/39-6-3-8-0-0-0-0-0-0.html" target="_blank" title="宅地造成工事規制区域">宅地造成工事規制区域</a></li>
                  <li><div className="k-map-k k-icon7"></div><a href="http://www.city.nagoya.jp/shisei/category/53-10-15-3-2-0-0-0-0-0.html" target="_blank" title="都市景観形成地区等">都市景観形成地区等</a></li>
                </ul>
              </div>
              <div className="k-mk-right">
                <ul className="k-map-key2">
                  <li><div className="k-map-k k-icon8"></div><a href="http://www.city.nagoya.jp/shisei/category/53-10-15-3-5-0-0-0-0-0.html" target="_blank" title="都市景観協定地区等">都市景観協定地区等</a></li>
                  <li><div className="k-map-k k-icon9"></div><a href="http://www.city.nagoya.jp/kankobunkakoryu/page/0000016762.html" target="_blank" title="町並み保存地区">町並み保存地区</a></li>
                  <li><div className="k-map-k k-icon10"></div><a href="http://www.city.nagoya.jp/jigyou/category/39-6-3-6-5-0-0-0-0-0.html" target="_blank" title="団地認定">団地認定</a></li>
                  <li><div className="k-map-k k-icon11"></div><a href="http://www.city.nagoya.jp/shisei/category/53-10-7-3-6-0-0-0-0-0.html" target="_blank" title="区画整理(組合)">区画整理(組合)</a></li>
                  <li><div className="k-map-k k-icon12"></div><a href="http://www.city.nagoya.jp/shisei/category/53-10-7-3-6-1-0-0-0-0.html" target="_blank" title="区画整理(公共団体)">区画整理(公共団体)</a></li>
                  <li><div className="k-map-k k-icon13"></div><a href="https://www.city.nagoya.jp/jutakutoshi/page/0000089771.html" target="_blank" title="木造住宅密集地域">木造住宅密集地域</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }

}
